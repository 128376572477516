@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
.full-access {
  font-size: $next-global-font-size;

  ul {
    margin-left: 1.5em;
    ul {
      margin-left: 2.5em;
      list-style-type: circle;
    }
  }

  ol {
    margin-left: 1.5em;
    ul {
      margin-left: 2.5em;
    }
  }
}

.full-access-banner {
  label {
    line-height: normal;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  button {
    cursor: pointer;
  }
}
