// Move these settings to specific components and extract the remainder back into InfoSight.
// Foundation is just another tool in the box, not a framework of canon.
@import 'util/util';

// 1. Global
// ---------
$global-font-size: 14px;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$primary-color: $brand-color;
$secondary-color: $medium-grey-02;
$success-color: $green-02;
$warning-color: $yellow-01;
$alert-color: $red-01;
$light-gray: $light-grey-01;
$medium-gray: $medium-grey-01;
$dark-gray: $dark-grey-01;
$black: #0a0a0a !default;
$white: #fefefe !default;
$body-background: $white;
$body-font-color: $dark-grey-02;
$body-font-family: 'Metric', 'Helvetica Neue', 'Helvetica', 'Roboto', 'Arial', sans-serif;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-text-direction: ltr;
$global-right: right;
$global-left: left;
$global-flexbox: true;
$foundation-palette: (
    primary: $primary-color,
    secondary: $secondary-color,
    success: $success-color,
    warning: $warning-color,
    alert: $red-01,
    nmbl: $brand-color,
    dark: $dark-grey-02
);

// 2. Breakpoints
// --------------

$breakpoints: (
    small: 0,
    medium: 640px,
    large: 1024px,
    xlarge: 1200px,
    xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 24;
$grid-column-gutter: (
    small: 10px,
    medium: 10px
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// 4. Base Typography
// ------------------
$header-font-family: $body-font-family;
$header-font-weight: $global-weight-normal;
$header-font-style: normal;
$font-family-monospace: 'Consolas', 'Liberation Mono', 'Courier', monospace;
$header-color: inherit;
$header-lineheight: 1.4;
$header-margin-bottom: 20;
$header-text-rendering: optimizeLegibility;

// After switching from Helvetica Neue to Metric, add a multiplier to increase font sizes but maintain the ratios
$header-font-multiplier: 1.25;
$header-styles: (
    'medium': (
        'h1': (
            'font-size': 20 * $header-font-multiplier,
            'line-height': $header-lineheight,
            'margin-top': 0,
            'margin-bottom': $header-margin-bottom
        ),
        'h2': (
            'font-size': 16 * $header-font-multiplier,
            'line-height': $header-lineheight,
            'margin-top': 0,
            'margin-bottom': $header-margin-bottom
        ),
        'h3': (
            'font-size': 14 * $header-font-multiplier,
            'line-height': $header-lineheight,
            'margin-top': 0,
            'margin-bottom': $header-margin-bottom
        ),
        'h4': (
            'font-size': 13 * $header-font-multiplier,
            'line-height': $header-lineheight,
            'margin-top': 0,
            'margin-bottom': $header-margin-bottom
        ),
        'h5': (
            'font-size': 12 * $header-font-multiplier,
            'line-height': $header-lineheight,
            'margin-top': 0,
            'margin-bottom': $header-margin-bottom
        ),
        'h6': (
            'font-size': 11 * $header-font-multiplier,
            'line-height': $header-lineheight,
            'margin-top': 0,
            'margin-bottom': $header-margin-bottom
        )
    )
);
$small-font-size: 80%;
$header-small-font-color: $medium-gray;
$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1rem;
$paragraph-text-rendering: optimizeLegibility;
$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
//$code-border: 1px solid $medium-gray;
$code-border: none;
$code-padding: rem-calc(2 5 1);
$anchor-color: $blue-01;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;
$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;
$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;
$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;
$cite-font-size: 1.0833rem;
$cite-color: $dark-gray;
$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;
$abbr-underline: 1px dotted $black;

// 5. Typography Helpers
// ---------------------
$lead-font-size: $global-font-size * 1.25;
$lead-lineheight: 1.6;
$subheader-lineheight: 1.4;
$subheader-color: $dark-gray;
$subheader-font-weight: $global-weight-normal;
$subheader-margin-top: 0.2rem;
$subheader-margin-bottom: 0.5rem;
$stat-font-size: 2rem;

// 6. Abide
// --------
$abide-inputs: true;
$abide-labels: true;
$input-background-invalid: $alert-color;
$form-label-color-invalid: $alert-color;
$input-error-color: $alert-color;
$input-error-font-size: 1rem;
$input-error-font-weight: $global-weight-bold;

// --------

// 10. Breadcrumbs
// ---------------
$breadcrumbs-margin: 0 0 $global-margin 0;
$breadcrumbs-item-font-size: 0.9167rem;
$breadcrumbs-item-color: $primary-color;
$breadcrumbs-item-color-current: $black;
$breadcrumbs-item-color-disabled: $medium-gray;
$breadcrumbs-item-margin: 0.75rem;
$breadcrumbs-item-uppercase: true;
$breadcrumbs-item-slash: true;
$breadcrumbs-item-uppercase: false;

// 13. Callout
// -----------

$callout-background-fade: 80%;

// 14. Close Button
// ----------------
$closebutton-position: right top;
$closebutton-offset-horizontal: 1rem;
$closebutton-offset-vertical: 0.5rem;
$closebutton-size: 2em;
$closebutton-lineheight: 1;
$closebutton-color: $dark-gray;
$closebutton-color-hover: $black;

// 15. Drilldown
// -------------
$drilldown-transition: transform 0.15s linear;
$drilldown-arrows: true;
$drilldown-arrow-color: $primary-color;
$drilldown-background: $white;

// 18. Flex Video
// --------------
$flexvideo-margin-bottom: rem-calc(16);
$flexvideo-ratio: 4 by 3;
$flexvideo-ratio-widescreen: 16 by 9;

// 19. Forms
// ---------
$fieldset-border: 1px solid $medium-gray;
$fieldset-padding: rem-calc(20);
$fieldset-margin: rem-calc(18 0);
$legend-padding: rem-calc(0 3);
$form-spacing: 1.333rem;
$helptext-color: #333;
$helptext-font-size: 1.0833rem;
$helptext-font-style: italic;
$input-prefix-color: $black;
$input-prefix-background: $light-gray;
$input-prefix-border: 1px solid $medium-gray;
$input-prefix-padding: 1rem;
$form-label-color: #757575;
$form-label-font-size: 1rem;
$form-label-font-weight: bold;
$form-label-line-height: 1.8;
$select-background: $white;
$select-triangle-color: #333;
$select-radius: $global-radius;
$input-color: $black;
$input-font-family: inherit;
$input-font-size: 1rem;
$input-background: $white;
$input-background-focus: $white;
$input-background-disabled: $light-gray;
$input-border: 1px solid $medium-gray;
$input-border-focus: 1px solid $dark-gray;
$input-shadow: inset 0 1px 2px rgba($black, 0.1);
$input-shadow-focus: 0 0 5px $medium-gray;
$input-cursor-disabled: default;
$input-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
$input-number-spinners: true;
$input-radius: $global-radius;

// 20. Label
// ---------
$label-background: $primary-color;
$label-color: $white;
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: 4px;

// 21. Media Object
// ----------------
$mediaobject-margin-bottom: $global-margin;
$mediaobject-section-padding: $global-padding;
$mediaobject-image-width-stacked: 100%;

// 22. Menu
// --------
$menu-margin: 0;
$menu-margin-nested: 1rem;
$menu-item-padding: 0 rem-calc(20);
$menu-icon-spacing: 0.25rem;
$menu-expand-max: 6;

// 23. Off-canvas
// --------------
$offcanvas-size: 250px;
$offcanvas-background: $light-gray;
$offcanvas-zindex: -1;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
$maincontent-shadow: 0 0 10px rgba($black, 0.5);

// 24. Orbit
// ---------
$orbit-bullet-background: $medium-gray;
$orbit-bullet-background-active: $dark-gray;
$orbit-bullet-diameter: 1.2rem;
$orbit-bullet-margin: 0.1rem;
$orbit-bullet-margin-top: 0.8rem;
$orbit-bullet-margin-bottom: 0.8rem;
$orbit-caption-background: rgba($black, 0.5);
$orbit-caption-padding: 1rem;
$orbit-control-background-hover: rgba($black, 0.5);
$orbit-control-padding: 1rem;
$orbit-control-zindex: 10;

// 28. Slider
// ----------
$slider-height: 0.5rem;
$slider-width-vertical: $slider-height;
$slider-background: $light-gray;
$slider-fill-background: $medium-gray;
$slider-handle-height: 1.4rem;
$slider-handle-width: 1.4rem;
$slider-handle-background: $primary-color;
$slider-opacity-disabled: 0.25;
$slider-radius: $global-radius;
$slider-transition: all 0.2s ease-in-out;

// 29. Switch
// ----------
$switch-background: $medium-gray;
$switch-background-active: $primary-color;
$switch-height: 2rem;
$switch-height-tiny: 1.5rem;
$switch-height-small: 1.75rem;
$switch-height-large: 2.5rem;
$switch-radius: $global-radius;
$switch-margin: $global-margin;
$switch-paddle-background: $white;
$switch-paddle-offset: 0.25rem;
$switch-paddle-radius: $global-radius;
$switch-paddle-transition: all 0.25s ease-out;

// 30. Table
// ---------

// 31. Tabs
// --------
$tab-margin: 0;
$tab-background: $white;
$tab-background-active: $light-gray;
$tab-border: $light-gray;
$tab-item-color: foreground($tab-background, $primary-color);
$tab-item-background-hover: $white;
$tab-item-font-size: 0.875rem;
$tab-item-padding: 1.25rem 1.5rem;
$tab-expand-max: 6;
$tab-content-background: $white;
$tab-content-border: $light-gray;
$tab-content-color: foreground($tab-background, $primary-color);
$tab-content-padding: 1rem;

// 32. Thumbnail
// -------------
$thumbnail-border: solid 4px $white;
$thumbnail-margin-bottom: $global-margin;
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);
$thumbnail-transition: box-shadow 200ms ease-out;
$thumbnail-radius: $global-radius;

// 33. Title Bar
// -------------
$titlebar-background: $black;
$titlebar-color: $white;
$titlebar-padding: 0.5rem;
$titlebar-text-font-weight: bold;
$titlebar-icon-color: $white;
$titlebar-icon-color-hover: $medium-gray;
$titlebar-icon-spacing: 0.25rem;

// 34. Tooltip
// -----------

//$tooltip-background-color: $black;
$tooltip-background-color: $white;
//$tooltip-color: $white;
$tooltip-color: $dark-grey-02;
$tooltip-padding: 0.75rem;
$tooltip-font-size: $small-font-size;
//$tooltip-pip-width: 0.75rem;
$tooltip-pip-width: 0;
//$tooltip-pip-height: $tooltip-pip-width * 0.866;
$tooltip-pip-height: 0;
$tooltip-pip-offset: 1.25rem;
//$tooltip-radius: $global-radius;
$tooltip-radius: 2px;

// --- Stuff to make upgrading foundation work
// ---
// ---
$global-color-pick-contrast-tolerance: 0;
$global-menu-padding: $menu-item-padding;
$global-menu-nested-margin: 1rem;
$global-prototype-breakpoints: false;

/// Global value used for margin on components.
/// @type Number
$global-margin: 1rem !default;

/// Global value used for padding on components.
/// @type Number
$global-padding: 1rem !default;

/// Global value used for positioning on components.
/// @type Number
$global-position: 1rem !default;

/// Global font weight used for normal type.
/// @type Keyword | Number
$global-weight-normal: normal !default;

/// Global font weight used for bold type.
/// @type Keyword | Number
$global-weight-bold: bold !default;

/// Global value used for all elements that have a border radius.
/// @type Number
$global-radius: 0 !default;

/// Global value used for all menu styles. Can be overwritten at individual menu component level.
/// @type Number
$global-menu-padding: 0.7rem 1rem !default;

/// Global value used for all menu styles. Nested margin for submenu.
$global-menu-nested-margin: 1rem !default;

/// Sets the text direction of the CSS. Can be either `ltr` or `rtl`.
/// @type Keyword
$global-text-direction: ltr !default;

/// Enables flexbox for components that support it.
/// @type Boolean
$global-flexbox: true !default;

/// Enabled responsive breakpoints for prototypes if applicable
/// @type Boolean
$global-prototype-breakpoints: false !default;

/// Button cursor's value, `auto` by default
/// @type Keyword
$global-button-cursor: pointer !default;
