@import "react-table";
@import "./filters/filterStyles";
@import "./actions/style";

// TODO: Clean this

@mixin elmer-Table {
    @include react-table;
    @include elmer-Table-Filter;
    @include elmer-Table-Actions;

    .elmer-Table-ColumnHeader {
        width: 100%;

        > div {
            padding: 4px;
        }

        &__sort-icon {
            color: $medium-grey-03;
            cursor: pointer;

            &:hover {
                color: inherit;
            }
        }
    }

    .elmer-Table {
        $table-row-hover: #e1f4fa;
        $table-stripe: even;
        $show-header-for-stacked: true;
        $favorite-background-color-even: rgba(0, 0, 0, 0.1);
        $favorite-background-color-odd: rgba(0, 0, 0, 0.07);

        height: 100%;

        // The rules below override the default react-table styling
        // TODO Rather than override, refactor react-table.scss to add these rules inline.
        .ReactTable {
            display: flex;
            width: 100%;
            min-height: 0;
            flex: 1;

            .rt-table {
                flex: 0 1 auto;
            }

            div.rt-noData {
                // Default react-table value was 50% TODO: Fix this better, absolute values always breaks
                top: 30%;
                background: transparent;
            }

            div.rt-thead {
                background: white;
                white-space: nowrap;
                overflow: visible;
                font-size: 0.91em;
                text-transform: uppercase;
                font-weight: normal;

                &:hover {
                    color: $dark-grey-01;
                }

                &.-headerGroups {
                    background-color: $light-grey-03;
                    border-bottom: none;

                    div.rt-th {
                        border-bottom: 2px solid #999;

                        &:empty {
                            border-bottom: none;
                        }
                    }
                }

                &.-header {
                    box-shadow: none;

                    div.rt-th { /* stylelint-disable-line no-descending-specificity */
                        &:hover {
                            background-color: $light-grey-05;
                        }

                        .rt-resizer {
                            // Adjusted width and right to make the resize handler thinner
                            width: 12px;
                            right: -10px;
                        }

                        &.rt-resizable-header:last-child .rt-resizer {
                            width: 6px;
                            right: 0;
                        }
                    }
                }

                .rt-th {
                    &.-sort-desc {
                        box-shadow: none;
                    }

                    &.-sort-asc {
                        box-shadow: none;
                    }
                }

                > div.rt-tr > div.rt-th { /* stylelint-disable-line no-descending-specificity */
                    border-right: 1px solid $medium-grey-03;

                    &:focus {
                        outline: none;
                    }
                }
            }

            div.rt-tbody {
                border-top: 2px solid #999;
                background-color: $white;

                // Disable horizontal scroll bar within the body
                max-width: 100%;
                overflow-x: hidden;

                div.rt-td {
                    border-right: none;
                    // Spacing for the td's content to align it properly to the header
                    padding: 8px 8px 6px 8px;
                }

                div.rt-tr-group {
                    div.rt-tr {
                        border-bottom: 1px solid $light-gray;

                        &:not(.-padRow):hover {
                            background: $table-row-hover;
                        }

                        &.-even {
                            background-color: $table-stripe;
                        }
                    }

                    div.rt-tr.elmer-group-favorite-systems {
                        border-bottom: 1px solid $light-gray;

                        &:not(.-padRow):hover {
                            background: $table-row-hover;
                        }

                        &.-even {
                            background-color: $favorite-background-color-even;
                        }

                        &.-odd {
                            background-color: $favorite-background-color-odd;
                        }
                    }
                }
            }

            .pagination-bottom {
                margin: 28px 12px 8px 8px;
            }
        }

        .elmer-Toggle .elmer-Toggle__button {
            &--off {
                border: 1px solid #36373a;
                margin-left: 5px;
            }
        }
    }
}
