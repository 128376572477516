@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
@import '../../../node_modules/@infosight/elmer/dist/sass/elmer-navbar-settings';

.search {
  @include elmer-NavBar-SearchInput(());
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: map_get($elmer-navbar-settings, color);
    opacity: 1; /* Firefox */
  }
}
