@mixin elmer-form-utils {
    $sizes: (large: 320px, medium: 210px, small: 100px, xsmall: 45px);

    .field-width {
        @each $size, $width in $sizes {
            &--#{$size} {
                width: $width;
            }
        }
    }

    // Resets
    [type='checkbox'],
    [type='file'],
    [type='radio'],
    [type='color'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='email'],
    [type='month'],
    [type='number'],
    [type='password'],
    [type='search'],
    [type='tel'],
    [type='text'],
    [type='time'],
    [type='url'],
    [type='week'],
    input,
    select,
    textarea {
        margin: 0;
    }
}

@mixin elmer-text-utils {
    .text-primary {
        color: $dark-grey-01;
    }

    .text-secondary {
        color: $medium-grey-02;
    }

    .text-success {
        color: $success-color;
    }

    .text-danger {
        color: $red-01;
    }

    .text-warning {
        color: $orange-01;
    }

    .text-inverted {
        color: $light-grey-05;
    }

    .text-uppercase {
        // TODO find this rule throughout and replace with this class
        text-transform: uppercase;
    }

    .ellipsis-on-overflow {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .wrap-on-overflow {
        white-space: normal;
    }

    .list-unstyled {
        list-style-type: none;
    }
}

@mixin elmer-font-base {
    $hpe-fonts-path: "https://d3hq6blov2iije.cloudfront.net/fonts/HPEXS-Metric-Fonts" !default;

    @font-face {
        font-family: "Metric";
        font-display: swap;
        src:
            url("#{$hpe-fonts-path}/MetricHPEXS-Regular.woff2") format('woff2'),
            url("#{$hpe-fonts-path}/MetricHPEXS-Regular.woff") format('woff');
    }

    @font-face {
        font-family: "Metric";
        font-display: swap;
        src:
            url("#{$hpe-fonts-path}/MetricHPEXS-Bold.woff2") format('woff2'),
            url("#{$hpe-fonts-path}/MetricHPEXS-Bold.woff") format('woff');
        font-weight: 700;
    }

    @font-face {
        font-family: "Metric";
        font-display: swap;
        src:
            url("#{$hpe-fonts-path}/MetricHPEXSSemibold-Regular.woff2") format('woff2'),
            url("#{$hpe-fonts-path}/MetricHPEXSSemibold-Regular.woff") format('woff');
        font-weight: 600;
    }

    @font-face {
        font-family: "Metric";
        font-display: swap;
        src:
            url("#{$hpe-fonts-path}/MetricHPEXSMedium-Regular.woff2") format('woff2'),
            url("#{$hpe-fonts-path}/MetricHPEXSMedium-Regular.woff") format('woff');
        font-weight: 500;
    }

    @font-face {
        font-family: "Metric";
        font-display: swap;
        src:
            url("#{$hpe-fonts-path}/MetricHPEXSLight-Regular.woff2") format('woff2'),
            url("#{$hpe-fonts-path}/MetricHPEXSLight-Regular.woff") format('woff');
        font-weight: 100;
    }
}

@mixin elmer-visibility {
    // Basic hiding classes
    .hide {
        display: none !important;
    }

    .invisible {
        visibility: hidden;
    }
}

@mixin elmer-typography {
    h1 {
        font-weight: 400;
    }

    h2,
    h3,
    h4,
    h5 {
        font-weight: bold;
    }

    h6 {
        text-transform: uppercase;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        &:not(.inherit-color) {
            color: $dark-grey-02;
        }

        &.no-margin {
            margin: 0;
        }
    }
}

@mixin elmer-style-utils {
    @include elmer-font-base;
    @include elmer-visibility;
    @include elmer-typography;
    @include elmer-form-utils;
    @include elmer-text-utils;

    .no-margin {
        margin: 0;
    }

    .inline-flex-container {
        display: inline-flex;
    }
}
