@import "mixins";

@mixin elmer-infrastructure-ScopeSelectionTree {
    $background-color: $white;
    $hover-color: $black;
    $active-color: $dark-grey-01;
    $inactive-color: $medium-grey-02;
    $font-weight: normal;
    $font-size: 1.333rem;

    .elmer-infrastructure-ScopeSelectionTree {
        @include elmer-infrastructure-tree-caret;

        &__icon {
            padding-right: 1ch;
        }

        &__non-scope-node {
            color: $inactive-color;
        }

        &__non-scope-node,
        &__scope-node {
            white-space: nowrap;
        }

        &__scope-node {
            font-weight: $font-weight;
            color: $active-color;

            &:focus,
            &:active,
            &:hover {
                color: $hover-color;
            }
        }
    }
}
