@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
@import '../../../node_modules/@infosight/elmer/dist/sass/elmer-navbar-settings';

$padding: 20px;

.container {
  font-size: map_get($elmer-navbar-settings, font-size);
  //min-width: 420px;
  overflow: hidden;

  // this is a hacky fix to deal with popover using transform3d
  // which doesnt work correctly with chrome v80
  // if we update react-popover to v2 check that we can remove the absolute position
  width: 550px;
  position: absolute;
  top: 0px;
  left: -300px;
}

.input {
  @include elmer-NavBar-SearchInput(
    (
      background-color: map_get($elmer-navbar-settings, background-color),
    )
  );

  label {
    font-size: 1em;
  }
}

.results {
  color: map_get($elmer-navbar-settings, color);
  background-color: map_get($elmer-navbar-settings, secondary-background-color);
  padding: $padding 0;
  overflow-y: auto;
  margin: 0;

  &:empty {
    display: none;
  }
}

.topic {
  padding: 0 $padding;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  &:hover {
    color: map_get($elmer-navbar-settings, active-color);
    font-weight: map_get($elmer-navbar-settings, active-font-weight);
  }
}

.hit {
  color: map_get($elmer-navbar-settings, color);

  &:hover,
  &:focus {
    color: map_get($elmer-navbar-settings, active-color);
    font-weight: map_get($elmer-navbar-settings, active-font-weight);
  }
}

.no-match {
  padding: 0 $padding;
}
