@mixin elmer-DataList {
    .elmer-DataList {
        width: 600px;
    }

    .elmer-DataList__label {
        color: $medium-grey-02;
        font-size: 12px;
    }

    .elmer-DataList__value {
        margin-bottom: 20px;
        font-size: $next-global-font-size;
    }
}
