@mixin elmer-Tabs {
    $elmer-tabs-background-color: transparent;
    $elmer-tab-background-color: $light-grey-04;
    $elmer-tab-color: $dark-grey-02;
    $elmer-tab-selected-background-color: white;
    $elmer-tab-selected-color: $elmer-tab-color;
    $elmer-border-color: $medium-grey-03;
    $height: 40px;

    .react-tabs {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        &:first-child {
            margin-top: 8px;
        }

        &__tab-list {
            height: $height;
            list-style: none;
            margin: 0;
            padding: 0 0 0 8px;
            display: flex;
            line-height: $height;
            font-size: 13 / 12rem;
            color: $elmer-tab-color;
            background-color: $elmer-tabs-background-color;
        }

        &__tab,
        .elmer-Tabs__TabMenu {
            display: inline-flex;
            align-items: center;
            padding: 0 20px;
            height: 100%;
            line-height: inherit;
            cursor: pointer;
            color: $elmer-tab-color;
            position: relative;
            background-color: $elmer-tab-background-color;
        }

        &__tab {
            cursor: pointer;
            border: 1px solid $elmer-border-color;
            border-left-width: 0;

            &:first-child {
                border-left-width: 1px;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: $black;
            }

            &--selected {
                color: $elmer-tab-selected-color;
                border-top: 2px solid $brand-color;
                border-bottom: none;
                background: $elmer-tab-selected-background-color;
                font-weight: bold;
                z-index: 1;

                &:hover,
                &:focus {
                    color: inherit;
                }
            }

            &--disabled {
                display: none;
            }
        }

        // Show only the active/selected tab-panel
        &__tab-panel {
            display: none;
        }

        &__tab-panel--selected {
            display: block;
            border-top: 1px solid $elmer-border-color;
            background: linear-gradient(to bottom, white, white 20%, transparent) no-repeat;
            background-size: 100% 40px;
            margin-top: -1px;
        }

        .elmer-Tabs__TabMenu {
            // FIXME This probably doesn't work with flexbox
            float: right;
        }
    }
}
