:export {
  textColor: #757575;
  checkBoxColor: #049f77;
  borderColor: #eee;
}

/* increased specificity to overwrite grommet min-width */

div.container {
  min-width: 384px;
  font-size: 18px;
  color: #757575;
  background-color: #fff;
}

div.container a {
  flex-grow: 1;
  color: #757575;
}

.element,
.subelement {
  height: 50px;
}

.element:focus,
.subelement:focus,
.element:hover,
.subelement:hover {
  background-color: #fafafa;
}

.element.active,
.active.subelement {
  background-color: #f4f6f9;
  font-weight: bold;
  border-left: 4px solid #01a982;
  padding-left: 19px;
}

.subelement {
  font-size: 16px;
  height: 40px;
}

.subelement.active {
  padding-left: 43px;
}

.active-menu-pulldown {
  background-color: #fff;
  font-weight: bold;
}

/* increased specificity to overwrite grommet cursor */

div.non-selectable,
div.title,
div.title-bold-green {
  cursor: default;
  font-weight: normal;
}

div.title,
div.title-bold-green {
  font-weight: bold;
  height: 68px;
}

div.title:hover .title-highlight,
div.title-bold-green:hover .title-highlight {
  background-color: #cacaca;
}

div.title-bold-green {
  color: #01a982;
  background-color: white;
}

.menu-icon {
  color: #fff;
  font-size: 24px;
}

.scroll {
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

div.tenant {
  color: #757575;
  font-weight: bold;
}

.icon-background {
  margin-bottom: 8px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  align-self: center;
}

.accordion-panel {
  /* sub-subelement */
}

.accordion-panel .menu-pulldown {
  border-bottom-width: 0;
}

.accordion-panel div {
  border-color: #eee;
}

.accordion-panel .accordion-panel div,
.accordion-panel .subelement {
  border-bottom-width: 0;
}

.accordion-panel .accordion-panel .subelement.active {
  padding-left: 66px;
}

.open-button button[class^='Beacon'] {
  z-index: 999999;
}

.close-button {
  position: absolute;
  top: -70px;
  left: 20px;
  background-color: #435563;
  cursor: pointer;
}

