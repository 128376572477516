@mixin elmer-infrastructure-tree-caret {
    $active-color: $dark-grey-01 !default;

    &__caret {
        display: inline-block;
        width: 2ch;
        text-align: left;
        color: $active-color;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}
