// Foundation Settings
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-color;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: #fff;
$button-color-alt: #000;
$button-padding: 0.58rem 1.2rem;
$button-radius: 0;
$button-sizes: (
    tiny: 0.75rem,
    small: 0.9rem,
    default: 1rem,
    large: 1.25rem,
);
$button-opacity-disabled: 0.25;
$button-palette: $foundation-palette;
$buttongroup-margin: 1rem;
$buttongroup-spacing: 1px;
$buttongroup-child-selector: '.button';
$buttongroup-expand-max: 6;

@import "~foundation-sites/scss/components/button";
@import "~foundation-sites/scss/components/button-group";

@mixin elmer-Button {
    @include foundation-button;
    @include foundation-button-group;

    button {
        font-family: $body-font-family;
    }

    .button {
        padding: 7px 15px;
        margin-bottom: 0;
        font-weight: 600;
        text-transform: none;
    }

    .button.secondary {
        border-color: $brand-color;
        color: $brand-color;
        background: none;

        &:hover,
        &:focus {
            border-color: $brand-color;
            color: $brand-color;
            background: none;
        }
    }

    .button.hollow {
        border-color: $dark-grey-02;
        color: $dark-grey-02;
        background: none;

        &:hover,
        &:focus {
            background: none;
            color: $dark-grey-02;
            border-color: $dark-grey-02;
        }
    }

    .button.dark {
        color: $white;
        background: $dark-grey-02;

        &:hover {
            background: darken($dark-grey-02, 10%);
            color: $white;
        }
    }

    .button.cancel {
        background: none;
        color: $brand-color;
        border: none;

        &:hover {
            background: none;
        }

        &:focus {
            background: none;
        }
    }

    .button.hollow[disabled] {
        color: #ccc;
        border: 1px solid #ccc;
        background: none;
        opacity: inherit;
    }

    .button-group {
        margin-bottom: 0;

        .button:not(:last-child) {
            border-right: 0;
            margin-right: 1px;
        }
    }

    .button.secondary.dropdown::after {
        border-color: #3e78b1 transparent transparent;
        top: 0.25rem;
    }

    .button.wide {
        width: 10em;
    }

    .elmer-Button {
        &--uppercase {
            text-transform: uppercase;
        }
    }

    .button.alert {
        // Override auto contrast-picked-colors
        color: $white;

        &:hover,
        &:focus {
            color: $white;
        }
    }

    // Override the styling for "cancel: buttons of all colors so they have the correct hollow+borderless styling
    // The unused $color variable is required here because we're iterating over a map
    @each $name, $color in $button-palette {
        .button.#{$name}.cancel {
            border: none;
        }
    }
}
