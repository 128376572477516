@mixin elmer-NavBar-Meganav($shared, $prefix) {
    $menu-color: map_get($shared, color);
    $menu-color-active: map_get($shared, active-color);
    $menu-opacity: map_get($shared, opacity);
    $menu-opacity-active: map_get($shared, active-opacity);
    $menu-border: 1px solid $menu-color;
    $menu-border-active: 1px solid $menu-color-active;
    $submenu-color: map_get($shared, color);
    $submenu-color-active: map_get($shared, active-color);
    $submenu-opacity: map_get($shared, opacity);
    $submenu-opacity-active: map_get($shared, active-opacity);
    $submenu-border: 4px solid map_get($shared, background-color);
    $submenu-border-active: 4px solid $brand-color;

    #{$prefix}-Meganav {
        background-color: map_get($shared, background-color);
        padding: map_get($shared, item-padding);
        font-size: map_get($shared, font-size);

        > ul {
            background-color: initial;
            margin-left: 0;
            list-style: none;
            align-items: stretch;
            display: flex;
        }
    }

    #{$prefix}-MeganavItem {
        &__menu {
            flex: 0 1 auto;
            padding: 0;
            margin-right: map_get($shared, item-padding);

            @extend %fade;

            > ul {
                flex-wrap: wrap;
                flex: 1 1 auto;
                background-color: initial;
                margin: 0;
            }

            > h1 {
                color: $menu-color;
                opacity: $menu-opacity;
                border-bottom: $menu-border;
                padding: 0 map_get($shared, item-padding) map_get($shared, item-padding) / 2;
                margin: 0 0 map_get($shared, item-padding) / 2;

                @extend %fade;
            }

            &:hover {
                > h1 {
                    border-bottom: $menu-border-active;
                    color: $menu-color-active;
                    opacity: $menu-opacity-active;
                }
            }
        }

        &__submenu {
            flex: 0 auto;
            min-width: map_get($shared, min-item-width);

            @extend %fade;

            &[data-depth="1"] {
                border-bottom: $submenu-border;
                padding-bottom: map_get($shared, item-padding) / 2;
            }

            h2,
            h3,
            h4,
            h5,
            h6 {
                color: $submenu-color;
                opacity: $submenu-opacity;
                margin: 0;
                padding: 0 map_get($shared, item-padding);
                line-height: map_get($shared, link-height);

                @extend %fade;
            }

            > ul {
                line-height: map_get($shared, link-height);
                margin: 0;
            }

            a {
                @extend %anchor-fade;

                &:hover,
                &:focus {
                    color: $submenu-color-active;
                    opacity: $submenu-opacity-active;
                    font-weight: map_get($shared, active-font-weight);
                }
            }

            a,
            #{$prefix}-MeganavItem--title {
                padding: 0 map_get($shared, item-padding);
                display: flex;
                color: $submenu-color;
                opacity: $submenu-opacity;
            }

            &:hover {
                &[data-depth="1"] {
                    border-bottom: $submenu-border-active;
                }

                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: $submenu-color-active;
                    opacity: $submenu-opacity-active;
                }

                a {
                    &:hover,
                    &:focus {
                        color: $submenu-color-active;
                        opacity: $submenu-opacity-active;
                        font-weight: map_get($shared, active-font-weight);
                        background-color: map_get($shared, hover-background-color);
                    }
                }
            }
        }
    }
}
