@mixin elmer-NavBar-AdaptiveNode($shared, $prefix) {
    #{$prefix}-AdaptiveNode {
        &--external > .fa {
            font-size: 0.7em;
            padding-left: map_get($shared, item-padding) / 2;
        }

        // DO NOT REMOVE this until upgrading to Grommet.latest, where it is fixed
        // Stack creates an inner div with display: block, which messes with styles from StaticBold.
        // If this is broken, the "Menu with Beacons" story will very obviously render incorrectly.
        &__Hack_StaticBold_Inside_BetaGrommetStack > div:first-child {
            display: flex;
        }
    }
}
