/* stylelint-disable no-descending-specificity */
// Foundation Settings
$table-background: $white;
$table-color-scale: 5%;
$table-border: none;
$table-padding: 8px;
$table-hover-scale: 2%;
$table-row-hover: #e1f4fa;
$table-row-stripe-hover: #e1f4fa;
$table-is-striped: true;
$table-striped-background: $light-grey-05;
$table-stripe: even;
$table-head-background: $white;
$table-head-row-hover: darken($table-head-background, $table-hover-scale);
$table-foot-background: smart-scale($table-background, $table-color-scale);
$table-foot-row-hover: darken($table-foot-background, $table-hover-scale);
$table-head-font-color: $body-font-color;
$table-foot-font-color: $body-font-color;
$show-header-for-stacked: true;
$header-padding: 0.15rem 0.3rem 0.15rem 0.6rem;

@import "~foundation-sites/scss/components/table";

@mixin elmer-GridView {
    @include foundation-table;

    .elmer-grid-view {
        min-height: 200px;

        &__fixed-header {
            min-height: 10px;
            background-color: white;
        }

        &.gv-subquery-filters {
            input {
                width: 100%;
            }
        }

        &__footer {
            border-top: 1px solid $light-grey-03;
            min-height: 30px;
            background-color: $light-grey-05;
            padding: 10px;
        }

        &__column--xlarge {
            width: 18rem;
        }

        &__column--large {
            width: 12rem;
        }

        &__column--medium {
            width: 10rem;
        }

        &__column--small {
            width: 8rem;
        }

        &__column--xsmall {
            width: 6rem;
        }
    }

    table.elmer-grid-view, // Allow this style to be used outside of GridView
    .elmer-grid-view > table { // Used directly by GridView
        border-collapse: collapse;
        margin: 0;

        &.table-border {
            border: 0.01rem solid $light-grey-01;
        }

        > tbody > tr > td,
        > thead > tr > th {
            max-width: 0;
        }

        thead {
            border-top: 0.01rem solid $light-grey-03;

            tr {
                border-bottom: 2px solid #999;
            }

            th {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: visible;
                font-size: 0.91rem;
                text-transform: uppercase;
                font-weight: normal;
                border-right: 0.1rem solid $medium-grey-03;
                padding: $header-padding;

                & a {
                    color: $black;

                    &:hover {
                        color: $dark-grey-01;
                    }
                }

                &[data-colname]:not([data-group-header]) {
                    &:hover {
                        background-color: $light-grey-05;
                    }
                }

                &[data-sortable] {
                    cursor: pointer;
                }

                .sortIcon {
                    color: $medium-grey-03;
                }

                &:hover {
                    .sortIcon {
                        color: inherit;
                    }

                    &.filter-hover .sortIcon {
                        color: $medium-grey-03;
                    }
                }

                .wrap-on-overflow {
                    white-space: normal;
                }

                &[data-group-header] {
                    padding: $header-padding;
                    background-color: $light-grey-03;
                }

                [type=color],
                [type=date],
                [type=datetime-local],
                [type=datetime],
                [type=email],
                [type=month],
                [type=number],
                [type=password],
                [type=search],
                [type=tel],
                [type=text],
                [type=time],
                [type=url],
                [type=week],
                textarea,
                select {
                    margin: 0;
                }
            }
        }

        tbody {
            tr {
                &.is-selected {
                    background: $table-row-hover;
                }

                &:nth-child(odd),
                &:nth-child(even) {
                    border-bottom: 1px solid $light-gray;

                    &:hover {
                        background: $table-row-hover;
                    }
                }
            }
        }
    }

    .elmer-grid-view-FilterDropdown {
        position: relative;
        padding: 0;

        button {
            cursor: pointer;
        }

        .filterIcon {
            color: $medium-grey-03;
        }

        &--active .filterIcon,
        :hover .filterIcon {
            color: inherit;
        }

        &--active .elmer-grid-view-FilterDropdown__popper {
            z-index: 4;
            background-color: white;
            padding: 10px 20px 20px;
            width: 240px;
            border: 1px solid $medium-grey-03;
            white-space: normal;
        }

        &--active .elmer-grid-view-FilterDropdown__arrow {
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            margin: 5px;
            border-width: 0 5px 5px;
            border-color: transparent transparent $medium-grey-03 transparent;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}
