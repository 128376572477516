@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
.logo {
  width: 32px;
  height: 40px;
  fill: $brand-color;
  margin-right: 6px;
}

.title {
  font-weight: 600;
  font-size: 24px;
  color: $white;
  margin-right: 15px;
}

.title-container {
  height: 100%;
}

.separator {
  margin-top: auto;
  margin-bottom: auto;
  height: 42px;
  border-left: solid #ddd 1px;
}

.navbar:global(.elmer-NavBar) {
  background-color: #435563;
  :global(.top-bar-left),
  :global(.top-bar-right) {
    background-color: #435563;
    height: 90px;
  }
  ul {
    background-color: #435563;
  }
  // if you just use elmer-NavBar-Dropdown the specificity is not always enough to get the index to stick
  // this sets the index back to elmers 5 after oculus sets it to 99999
  // curo modals live at 20 so in order to get them to show on top we need to override oculus
  div[class='flex-container elmer-NavBar-Dropdown'] {
    z-index: 5;
  }
}
