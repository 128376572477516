@mixin elmer-infrastructure-Selector {
    $padding: 20px;
    $border-color: $medium-grey-03;
    $hover-bg-color: $white;
    $hover-color: $black;

    .elmer-infrastructure-Selector {
        $this: &;

        &__manager {
            position: relative;
            border-bottom: 2px solid transparent;
            margin-bottom: -2px;

            &:not(:first-of-type) #{$this}__trigger {
                border-left-width: 1px;
            }

            &:hover {
                border-bottom-color: $brand-color;
            }
        }

        &__trigger {
            white-space: pre;
            outline: none;
            position: relative;
            border: 0 solid transparent;
            border-right-width: 1px;

            &--active {
                background-color: $hover-bg-color;
                border-color: $border-color;

                // TODO leave these for easy customization or be pedantic?
                // TODO I don't think h6 is used anywhere
                h6,
                h1 {
                    color: $hover-color;
                }
            }
        }
    }
}
