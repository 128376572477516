@mixin elmer-infrastructure-RelatedObjects {
    .elmer-infrastructure-RelatedObjects {
        font-size: $next-global-font-size;
        margin: 0;

        &__trigger,
        &__caret {
            color: $anchor-color;
            cursor: pointer;
        }
    }
}
