.highlighted {
  background-color: rgba(255, 255, 255, 0.6);
  padding: 2px;
  color: #425563;
  font-weight: 600;
  margin-right: 8px;
  border-radius: 2px;
}

:global(.elmer-NavBar-Menu__item--link):hover .highlighted {
  background-color: #fff;
}

.popover-box {
  height: 22px;
}

.popover-box div[class='elmer-PopoverTrigger__container'] {
  height: 22px;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popover-box div[class='elmer-OverlayTrigger__popper'] {
  line-height: 24px;
  font-weight: normal;
  font-size: 16px;
}

.popover-text {
  font-weight: normal;
}

.tenent-name {
  font-weight: 600;
}

