@function header-size($breakpoint, $header) {
    @return map-get(map-get(map-get($header-styles, $breakpoint), $header), 'font-size') * 1px;
}

$brand-color: #01a982;
$brand-accent-colors: (#2ad2c9, #614767, #ff8d6d) !default;
$black: #000;
$white: #fff;
$dark-grey-01: #36373b;
$dark-grey-02: #444;
$dark-grey-03: #56575e;
$medium-grey-01: #666;
$medium-grey-02: #888;
$medium-grey-03: #ccc;
$light-grey-01: #e2e6ea;
$light-grey-02: #eef0f2;
$light-grey-03: #eee;
$light-grey-04: #f4f6f9;
$light-grey-05: #fafafa;
$green-01: $brand-color;
$green-02: #9dcf65;
$blue-01: #1973cb;
$blue-02: #1197eb;
$blue-03: #32b2f8;
$orange-01: #fc9935;
$orange-02: #ffad00;
$yellow-01: #ffd144;
$red-01: #f04953;
$red-02: #ff6363;
$error-background: rgba(211, 82, 77, 0.1);
$next-global-font-size: 16px;
