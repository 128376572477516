.error {
  padding: 0 15px;
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
}

.header {
  min-width: 300px;
  font-weight: inherit;
  display: inline-block;
}

.title {
  font-weight: inherit;
}

.search-menu {
  max-height: 600px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.tenant-selector-dropdown div[class='elmer-NavBar-AdaptiveNode elmer-NavBar-Menu__item elmer-NavBar-Menu__item--title'] {
  font-weight: normal;
}

.tenant-selector-dropdown li[data-menu-item='viewAllMyOrgs'] {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.tenant-selector-dropdown li[data-menu-item='searchAllMyOrgs'] {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}

.tenant-selector-dropdown ul[class='elmer-NavBar-Menu'] {
  margin: 0px;
}

.tenant-selector-dropdown .search-results-special {
  padding: 5px 15px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: thin;
}

