@mixin elmer-infrastructure-ViewSelector {
    $background-color: $white;
    $hover-color: $black;
    $active-color: $dark-grey-01;
    $inactive-color: $dark-grey-03;
    $font-weight: normal;

    .elmer-infrastructure-ViewSelector {
        &__menu {
            background-color: $white;
            min-width: 100%;
            max-height: calc(100vh - 163px);
            overflow-y: auto;
            z-index: 2;
            font-size: $next-global-font-size;
            border-radius: 0;
            border: 1px solid $medium-grey-03;
            border-left-width: 0;
        }

        &__anchor {
            font-weight: $font-weight;
            color: $inactive-color;
            white-space: nowrap;
            padding: 0 20px;
            display: inline-block;
            margin: 5px 0;

            &:focus,
            &:active {
                color: $active-color;
            }

            &:hover {
                color: $hover-color;
            }
        }

        &__icon {
            // Properly center the icon
            margin-top: -4px;
        }
    }
}
