@mixin elmer-PopoverTrigger {
    .elmer-PopoverTrigger {
        &__container {
            position: relative;
            display: inline-block;
            border-bottom: dotted 1px $dark-grey-01;
            font-weight: bold;
            cursor: help;
        }
    }
}
