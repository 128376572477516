@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
@import '../../../node_modules/@infosight/elmer/dist/sass/elmer-navbar-settings';

:export {
  textColor: $text-color;
  checkBoxColor: $check-box-color;
  borderColor: $border-color;
}

/* increased specificity to overwrite grommet min-width */
div.container {
  min-width: 384px;
  font-size: 18px;
  color: $text-color;
  background-color: $white;
  a {
    flex-grow: 1;
    color: $text-color;
  }
}

.element {
  &:focus,
  &:hover {
    background-color: $hover-background-color;
  }

  &.active {
    background-color: $active-background-color;
    font-weight: bold;
    border-left: 4px solid $brand-color;
    padding-left: 19px;
  }
  height: 50px;
}

.subelement {
  @extend .element;
  font-size: 16px;
  height: 40px;
  &.active {
    padding-left: 43px;
  }
}

.active-menu-pulldown {
  background-color: $white;
  font-weight: bold;
}

/* increased specificity to overwrite grommet cursor */
div.non-selectable {
  cursor: default;
  font-weight: normal;
}

div.title {
  @extend .non-selectable;
  font-weight: bold;
  height: 68px;

  &:hover .title-highlight {
    background-color: $icon-hover-background-color;
  }
}

div.title-bold-green {
  @extend .title;
  color: $brand-color;
  background-color: white;
}

.menu-icon {
  color: $white;
  font-size: 24px;
}

.scroll {
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

div.tenant {
  color: $text-color;
  font-weight: bold;
}

.icon-background {
  margin-bottom: 8px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
}

.icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  align-self: center;
}

.accordion-panel {
  .menu-pulldown {
    border-bottom-width: 0;
  }
  div {
    border-color: $border-color;
  }
  .accordion-panel div,
  .subelement {
    border-bottom-width: 0;
  }
  /* sub-subelement */
  .accordion-panel .subelement.active {
    padding-left: 66px;
  }
}

.open-button {
  button[class^='Beacon'] {
    z-index: 999999;
  }
}

.close-button {
  position: absolute;
  top: -70px;
  left: 20px;
  background-color: #435563;
  cursor: pointer;
}
