@mixin elmer-Legend {
    .elmer-Legend {
        width: 100%;
        margin: 0;

        &__unit::before {
            content: "\00a0";
        }

        &-Series {
            width: 100%;
            overflow: hidden;

            &::after {
                display: block;
                margin: 0 0.5ch;
                order: 2;
                flex-grow: 1;
                overflow: hidden;
                width: 0;
                min-width: 1em;
                content: "............................................................................................................" "............................................................................................................" "............................................................................................................" "............................................................................................................" "............................................................................................................";
            }

            &__icon {
                margin-right: 6px;
                border-radius: 2px;
                width: 10px;
                height: 10px;
                display: inline-block;
            }

            &__left {
                order: 1;
            }

            &__right {
                order: 3;
                flex: 0 auto;
                white-space: nowrap;
            }

            &--truncated {
                text-overflow: ellipsis;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;

                &:hover {
                    overflow: visible;
                }
            }
        }
    }
}
