@mixin elmer-infrastructure-ObjectBreadcrumbItem {
    $parent-color: #757575;

    .elmer-infrastructure-ObjectBreadcrumbItem {
        &__icon::after {
            content: "\00a0";
        }

        &__crumb {
            display: inline-flex;
            text-align: left;
            line-height: 1em;

            &:not(:last-of-type)::after {
                content: "\00a0/\00a0";
            }
        }
    }
}
