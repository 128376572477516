.logo {
  width: 32px;
  height: 40px;
  fill: #01a982;
  margin-right: 6px;
}

.title {
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  margin-right: 15px;
}

.title-container {
  height: 100%;
}

.separator {
  margin-top: auto;
  margin-bottom: auto;
  height: 42px;
  border-left: solid #ddd 1px;
}

.navbar:global(.elmer-NavBar) {
  background-color: #435563;
}

.navbar:global(.elmer-NavBar) :global(.top-bar-left),
.navbar:global(.elmer-NavBar) :global(.top-bar-right) {
  background-color: #435563;
  height: 90px;
}

.navbar:global(.elmer-NavBar) ul {
  background-color: #435563;
}

.navbar:global(.elmer-NavBar) div[class='flex-container elmer-NavBar-Dropdown'] {
  z-index: 5;
}

