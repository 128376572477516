// Foundation Settings
$dropdown-padding: 0;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;
$dropdown-sizes: (
    tiny: 100px,
    small: 200px,
    large: 400px,
);
$dropdownmenu-arrows: true;
$dropdownmenu-arrow-color: $anchor-color;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: $white;
$dropdownmenu-border: 1px solid $medium-gray;

@import "~foundation-sites/scss/components/dropdown";
@import "~foundation-sites/scss/components/dropdown-menu";
@import "~foundation-sites/scss/components/menu";

@mixin elmer-Dropdown {
    @include foundation-dropdown;
    @include foundation-dropdown-menu;

    .dropdown.menu > li.opens-right > .is-dropdown-submenu {
        right: auto;
        left: 0;
        top: 100%;
    }

    .dropdown.is-open .dropdown-pane {
        position: absolute;
        visibility: visible;
        display: initial;
    }

    .error {
        border: 1px solid $red-01;
        background-color: $error-background;
    }

    .input-error,
    .input-error:focus {
        @extend .error;
    }

    // Based on https://github.com/Fauntleroy/react-simple-dropdown/blob/master/styles/Dropdown.css
    .dropdown__content {
        @include dropdown-container;

        visibility: visible;
        display: none;

        @each $name, $size in $dropdown-sizes {
            &.#{$name} {
                width: $size;
            }
        }
    }

    .dropdown--active .dropdown__content {
        display: inline-block;
    }
}
