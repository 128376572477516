$card-background: $white;
$card-divider-background: $card-background;
$card-font-color: $dark-grey-02;
$card-border: 1px solid #ccc;
$card-shadow: none;
$card-border-radius: rem-calc(2);
$card-padding: 20px;
$card-margin-bottom: 0;
$card-margin: 8px;
$sides: (left, top, right, bottom) !default;

@import "~foundation-sites/scss/components/card";

@mixin elmer-Card {
    @include foundation-card;

    .card-divider {
        padding: $card-padding / 2 $card-padding;
        border-bottom: $card-border;

        &:nth-of-type(n+2) {
            border-top: $card-border;
        }

        &-no-border {
            border-bottom: 0;
            border-top: 0;
        }

        &.display-block {
            display: block;
        }

        & > .row {
            width: 100%;
        }
    }

    .card {
        margin: $card-margin;

        &-expand {
            flex: 1 1 auto;
        }

        &-no-expand {
            flex: 0;
        }

        &-no-margin {
            margin: 0;
        }

        &-no-border {
            border: none;
            border-radius: 0;
        }

        &-no-background,
        &-no-bg {
            background-color: inherit;
        }

        @each $side in $sides {
            &-#{$side}-margin {
                margin-#{$side}: $card-margin;
            }

            &-no-#{$side}-margin {
                margin-#{$side}: 0;
            }

            &-#{$side}-border {
                border-radius: 0;
                border-#{$side}: $card-border;
            }

            &-no-#{$side}-border {
                border-#{$side}: none;
            }

            &-#{$side}-padding {
                padding-#{$side}: $card-padding;
            }

            &-no-#{$side}-padding {
                padding-#{$side}: 0;
            }
        }
    }

    .card-section {
        &-padding {
            padding: $card-padding;
        }

        &-no-padding {
            padding: 0;
        }

        @each $side in $sides {
            &-#{$side}-padding {
                padding-#{$side}: $card-padding;
            }

            &-no-#{$side}-padding {
                padding-#{$side}: 0;
            }
        }
    }
}
