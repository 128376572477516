@import "foundation-settings";
@import "foundation";

@mixin elmer-foundation {
    @include foundation-global-styles;
    @include foundation-flex-grid;
    @include foundation-flex-classes;
    @include foundation-typography;

    // TODO Move to nimble microapp
    @include foundation-forms;
    @include foundation-callout;

    // Override
    select {
        padding: 0.2rem 0.28rem;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-border-radius: 0;
        background-position: right;
    }
}
