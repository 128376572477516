@mixin elmer-NoData {
    .elmer-NoData {
        height: 100%;
        width: 100%;
        background-color: $light-grey-01;
    }

    .elmer-NoData--block {
        display: flex;
    }

    .elmer-NoData--inline {
        display: inline-flex;
    }

    .elmer-NoData__message {
        margin: auto;
    }
}
