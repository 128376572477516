.search {
  padding: 10px 20px;
  background-color: #566676;
}

.search input[type="text"],
.search input[type="text"]:focus {
  flex: 1;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 18px;
  padding: 0;
  color: rgba(255, 255, 255, 0.6);
  background-color: #566676;
  border-bottom: 1px solid transparent;
}

.search input[type="text"]:focus {
  border-bottom-color: rgba(255, 255, 255, 0.6);
}

.search label {
  padding-right: 1ch;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
}

.search ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.6);
  opacity: 1;
  /* Firefox */
}

