@import "mixins";

@mixin elmer-infrastructure-DefaultScopeSelectionPane {
    $background-color: $white;
    $hover-color: $black;
    $active-color: $dark-grey-01;
    $inactive-color: $medium-grey-02;
    $font-weight: normal;
    $font-size: 1.333rem;

    .elmer-infrastructure-DefaultScopeSelectionPane {
        @include elmer-infrastructure-tree-caret;

        overflow: auto;

        &__search {
            font-size: $font-size;
        }

        &__search-input,
        &__search-input:focus {
            flex: 1;
            border: none;
            outline: none;
            box-shadow: none;
            font-size: $font-size;
            padding: 0;
            color: $inactive-color;
        }

        &__search-icon {
            padding-right: 2ch;
            font-size: $font-size;
            color: $inactive-color;
        }

        &__select-unscoped {
            font-weight: $font-weight;
            color: $active-color;

            &:focus,
            &:active,
            &:hover {
                color: $hover-color;
            }
        }

        &__icon {
            padding-right: 1ch;
        }
    }
}
