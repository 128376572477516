/* stylelint-disable no-descending-specificity */
// TODO the styling could be improved. Maybe get rid of the green and make everything a little smaller.
@mixin elmer-DatePicker {
    /* DayPicker styles */

    .DayPicker {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding-top: 1rem;
        padding-bottom: 0.5rem;
        user-select: none;
        font-size: 1rem;
        color: $dark-grey-02;
    }

    .DayPicker-Month {
        display: table;
        border-collapse: collapse;
        border-spacing: 0;
        user-select: none;
        margin: 0 1rem;
    }

    .DayPicker-NavBar {
        position: absolute;
        left: 0;
        right: 0;
        padding: 0 0.5rem;
    }

    .DayPicker-NavButton {
        position: absolute;
        cursor: pointer;
    }

    .DayPicker-NavButton--prev {
        left: 1rem;

        &::after {
            font-family: "FontAwesome"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
            content: '\f104';
            width: 1.5rem;
            height: 1.5rem;
            text-align: center;
            display: block;
        }

        &:hover {
            background-color: $light-grey-01;
        }
    }

    .DayPicker-NavButton--next {
        right: 1rem;

        &::after {
            font-family: "FontAwesome"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
            content: '\f105';
            width: 1.5rem;
            height: 1.5rem;
            text-align: center;
            display: block;
        }

        &:hover {
            background-color: $light-grey-01;
        }
    }

    .DayPicker-Caption {
        display: table-caption;
        height: 1.5rem;
        text-align: center;
        font-weight: bold;
    }

    .DayPicker-Weekdays {
        display: table-header-group;
    }

    .DayPicker-WeekdaysRow {
        display: table-row;
    }

    .DayPicker-Weekday {
        display: table-cell;
        padding: 0.5rem;
        font-size: 0.875em;
        text-align: center;
        color: #8b9898;
    }

    .DayPicker-Body {
        display: table-row-group;
    }

    .DayPicker-Week {
        display: table-row;
    }

    .DayPicker--interactionDisabled .DayPicker-Day {
        cursor: default;
    }

    .DayPicker-Day {
        display: table-cell;
        padding: 0.5rem;
        border: 1px solid #eaecec;
        text-align: center;
        cursor: pointer;
        vertical-align: middle;

        &:not(.DayPicker-Day--disabled):hover {
            background-color: $brand-color;
            color: $white;
        }
    }

    .DayPicker-Day--selected {
        background-color: $brand-color;
        color: $white;
    }

    /* Default modifiers */

    .DayPicker-Day--today {
        font-weight: bold;
    }

    .DayPicker-Day--disabled {
        color: #dce0e0;
        cursor: default;
        background-color: #eff1f1;
    }

    .DayPicker-Day--outside {
        color: $medium-grey-02;
    }
}
