@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
@import '../../../node_modules/@infosight/elmer/dist/sass/elmer-navbar-settings';

.trigger {
  align-items: stretch;
  font-size: map_get($elmer-navbar-settings, font-size);
  font-weight: normal;
  opacity: map_get($elmer-navbar-settings, opacity);
  flex: 1 1 auto;
  padding: 0 map_get($elmer-navbar-settings, item-padding);
  color: #ddd;

  &:focus,
  &:hover,
  &.active {
    color: map_get($elmer-navbar-settings, active-color);
    text-decoration: none;
    opacity: map_get($elmer-navbar-settings, active-opacity);
    cursor: pointer;
    font-weight: map_get($elmer-navbar-settings, active-font-weight);
  }
}
