.my_orgs_table div[class='rt-table'] {
  min-height: 175px;
}

.my_orgs_table .org-name {
  font-size: 18px;
}

.my_orgs_table .org-desc {
  word-wrap: normal;
  white-space: normal;
}

.search {
  width: 300px;
  border-bottom: 2px solid black;
  margin: 0px 0px 15px 8px;
}

.search input.search-input {
  border: 0px;
  box-shadow: none;
}

.browse-message {
  font-size: 18px;
  margin-bottom: 15px;
}

div[data-test-id='my-orgs-modal'] div[data-test-id='my-orgs-modal-content'] {
  margin-top: 5px;
  min-height: 500px;
}

