$height: 15px;
$thin-height: 5px;
$translate-y: 10px;
$progress-height: 1rem;
$progress-background: $light-gray;
$progress-margin-bottom: $global-margin;
$progress-meter-background: $primary-color;
$progress-radius: $global-radius;

@import "~foundation-sites/scss/components/progress-bar";

@mixin elmer-Progress {
    @include foundation-progress-bar;

    .progress.thin {
        line-height: $height;
        height: $thin-height;
        position: relative;
    }

    .elmer-ProgressWithValue {
        position: relative;

        %value {
            font-size: 1rem;
            position: absolute;
            top: -$translate-y;
        }

        &__left {
            @extend %value;

            left: 0;
        }

        &__right {
            @extend %value;

            color: $medium-grey-02;
            right: 0;
        }

        .progress.thin {
            top: $translate-y;
        }
    }

    .progress .progress-meter {
        box-sizing: border-box;
        border-right: 1px solid $medium-grey-03;
        background-color: nth($brand-accent-colors, 2);
    }

    .progress .danger {
        background-color: $red-01;
    }

    .progress,
    .progress-stacked {
        display: flex;
        height: 1rem;
        margin-bottom: 0;

        .meter,
        .progress-meter {
            float: left;
        }
    }

    .progress.noBackground {
        background-color: initial;
    }

    .progress.noBackground .progress-meter {
        border-right: none;
    }

    .progress .label {
        flex-grow: 1;
        margin: 0;
        padding-left: 0.5em;
        bottom: 5px;
        position: relative;
    }
}
