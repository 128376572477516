clr-icon {
  display: inline-block;
  margin: 0;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  fill: currentColor;
}

clr-icon > * {
  height: 100%;
  width: 100%;
  display: block;
  pointer-events: none;
}

clr-icon > svg {
  transition: inherit;
}

clr-icon > svg title {
  display: none;
}

