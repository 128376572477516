@mixin elmer-FacetedFilter {
    .elmer-FacetTags {
        padding: 7px;

        a {
            padding-right: 10px;
        }
    }

    .elmer-Facet {
        select {
            height: 27px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            -webkit-border-radius: 0;
            background-position: right;
        }

        label {
            width: 100%;
            font-weight: normal;
            color: $body-font-color;
        }

        .facet-error-msg {
            color: $red-01;

            .fa {
                padding-right: 5px;
                padding-top: 5px;
            }
        }

        .facet-error {
            background-color: $error-background;
            border: 1px solid $red-01;
        }

        // TODO create a separate component for this
        h6 {
            text-transform: none;
            font-size: 1rem;
        }

        .elmer-Unit {
            // This is the height of the elmer inputs
            line-height: 27px;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid rgba(208, 212, 218, 0.5);
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }

    .elmer-FacetGroup {
        > h6 {
            padding-bottom: 10px;
        }

        .elmer-Facet {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
}
