@mixin elmer-ChartUtils-SharedTooltips {
    .elmer-ChartUtils-SharedTooltips {
        text-align: right;

        &__ul {
            display: inline;
            margin: 0;
            list-style: none;
        }

        &__li {
            text-align: left;
            display: inline-block;
            //min-width: 10rem;
            padding-right: 1rem;

            &:last-child {
                padding-right: 0;
            }
        }

        &__svg {
            vertical-align: middle;
            height: 20px;
            width: 20px;
        }

        &__line-svg {
            height: 14px;
            width: 16px;
            margin-right: 2px;
        }

        &__x-value {
            vertical-align: middle;
            text-align: right;
        }

        &__name {
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: bold;

            &--plain-case {
                text-transform: none;
            }
        }
    }
}
