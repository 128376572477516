@import "../NavBar/public";
$elmer-secondary-navbar-settings: map_merge(
    $elmer-navbar-settings,
    (
        hover-background-color: #4a4b51,
        background-color: #566676,
        font-size: rem-calc(16px),
        height: 50px
    )
);

@mixin elmer-SecondaryNavBar {
    .elmer-SecondaryNavBar {
        height: map_get($elmer-secondary-navbar-settings, height);
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        padding: 0 1rem;
        z-index: 3;
        background-color: map_get($elmer-secondary-navbar-settings, background-color);
        color: map_get($elmer-secondary-navbar-settings, color);
        margin: 0;

        &__Title {
            color: $light-grey-05; // TODO add to settings

            &__name {
                font-size: map_get($elmer-secondary-navbar-settings, font-size);
                font-weight: 600;
            }

            &__label {
                line-height: 4px;
                margin-top: 7px;
                opacity: map_get($elmer-secondary-navbar-settings, opacity);
            }
        }
    }

    .elmer-SecondaryNavBar__nav-item {
        display: flex;
        align-items: stretch;
        padding: 0 1rem;

        > a {
            color: map_get($elmer-secondary-navbar-settings, color);
            opacity: map_get($elmer-secondary-navbar-settings, opacity);
            font-size: map_get($elmer-secondary-navbar-settings, font-size);
            flex: 1 1 auto;
            padding: 0 map_get($elmer-secondary-navbar-settings, item-padding);
            margin-top: 4px;
            border-bottom: 4px solid transparent;

            @extend %fade;

            &.active,
            &:hover,
            &:focus {
                color: map_get($elmer-secondary-navbar-settings, active-color);
                opacity: map_get($elmer-secondary-navbar-settings, active-opacity);
                text-decoration: none;
            }

            &.active {
                border-bottom-color: $brand-color;
                font-weight: map_get($elmer-secondary-navbar-settings, active-font-weight);
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}
