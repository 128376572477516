@mixin elmer-OverlayTrigger {
    // Based on https://github.com/FezVrasta/popper.js/blob/master/docs/css/popper.css
    .elmer-OverlayTrigger {
        &__popper {
            z-index: 1200; // Match z-index of foundation .tooltip
        }

        &__popper &__arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-color: $light-grey-01;
            position: absolute;
            margin: 5px;
        }

        &__popper[data-placement^="top"] {
            margin-bottom: 5px;
        }

        &__popper[data-placement^="top"] &__arrow {
            border-width: 5px 5px 0;
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            bottom: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }

        &__popper[data-placement^="bottom"] {
            margin-top: 5px;
        }

        &__popper[data-placement^="bottom"] &__arrow {
            border-width: 0 5px 5px;
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            top: -5px;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }

        &__popper[data-placement^="right"] {
            margin-left: 5px;
        }

        &__popper[data-placement^="right"] &__arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent;
            border-top-color: transparent;
            border-bottom-color: transparent;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }

        &__popper[data-placement^="left"] {
            margin-right: 5px;
        }

        &__popper[data-placement^="left"] &__arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }
}
