@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
@import '../../../node_modules/@infosight/elmer/dist/sass/elmer-navbar-settings';

.highlighted {
  background-color: map_get($elmer-navbar-settings, color);
  padding: 2px;
  color: map_get($elmer-navbar-settings, background-color);
  font-weight: map_get($elmer-navbar-settings, active-font-weight);
  margin-right: 8px;
  border-radius: 2px;
}

:global(.elmer-NavBar-Menu__item--link):hover .highlighted {
  background-color: map_get($elmer-navbar-settings, active-color);
}

.popover-box {
  height: 22px;
  // be careful with this type of selection.
  // it can be leaky if not properly contained in another class
  div[class='elmer-PopoverTrigger__container'] {
    height: 22px;
    border-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div[class='elmer-OverlayTrigger__popper'] {
    line-height: 24px;
    font-weight: normal;
    font-size: 16px;
  }
}

.popover-text {
  font-weight: normal;
}

.tenent-name {
  // semibold
  font-weight: 600;
}
