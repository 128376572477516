.role-select input[readonly] {
  background-color: transparent;
}

.role-select input[type='text'] {
  border: 0px;
  box-shadow: none;
}

input.other-role-input {
  border: 1px solid #01a982;
  border-radius: 6px;
}

button.cancel-button {
  border: 2px solid black;
}

