@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
@import '../../../node_modules/@infosight/elmer/dist/sass/elmer-navbar-settings';

.error {
  padding: 0 map_get($elmer-navbar-settings, item-padding);
  color: map_get($elmer-navbar-settings, color);
  opacity: map_get($elmer-navbar-settings, opacity);
}

.header {
  min-width: 300px;
  font-weight: inherit;
  display: inline-block;
}

.title {
  font-weight: inherit;
}

.search-menu {
  max-height: 600px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.tenant-selector-dropdown {
  div[class='elmer-NavBar-AdaptiveNode elmer-NavBar-Menu__item elmer-NavBar-Menu__item--title'] {
    font-weight: normal;
  }
  li[data-menu-item='viewAllMyOrgs'] {
    border-top: 1px solid map_get($elmer-navbar-settings, color);
  }
  li[data-menu-item='searchAllMyOrgs'] {
    border-top: 1px solid map_get($elmer-navbar-settings, color);
  }
  ul[class='elmer-NavBar-Menu'] {
    margin: 0px;
  }
  .search-results-special {
    padding: 5px 15px;
    color: map_get($elmer-navbar-settings, color);
    font-weight: thin;
  }
}
