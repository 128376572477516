@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
.my_orgs_table {
  div[class='rt-table'] {
    min-height: 175px;
  }
  .org-name {
    font-size: 18px;
  }
  .org-desc {
    word-wrap: normal;
    white-space: normal;
  }
}

.search {
  width: 300px;
  input.search-input {
    border: 0px;
    box-shadow: none;
  }
  border-bottom: 2px solid black;
  margin: 0px 0px 15px 8px;
}

.browse-message {
  font-size: 18px;
  margin-bottom: 15px;
}

// do not copy this. it is super hacky and potentially leaky
// curo doesnt offer any other identifiers so we are using this in order to override their styles
div[data-test-id='my-orgs-modal'] {
  div[data-test-id='my-orgs-modal-content'] {
    margin-top: 5px;
    min-height: 500px;
  }
}
