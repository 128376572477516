@mixin elmer-ScrollContainer {
    .elmer-ScrollContainer {
        height: 100%;
        display: flex;
        flex-direction: column;

        &__fixed {
            flex: 0 0 auto;
        }

        &__scrollable {
            flex: 1 1 auto;
            overflow: auto;
            height: 100%;

            // Moved from oculus-piano
            // this fixes a bug where the 100% width/height #hp-body-div appears on top of the lasik nav or not at all depending on other styles
            position: relative;
        }
    }

    .elmer-vertical-scroll-parent {
        height: 100%;
    }
}
