@mixin elmer-infrastructure-Title {
    $padding: 20px;

    .elmer-infrastructure-Title {
        &--is-trigger {
            cursor: pointer;
        }

        &__header {
            white-space: pre;
        }

        &__trigger-icon {
            line-height: 14px;
            padding-left: $padding/2 - 3;
        }

        &__icon {
            padding-right: $padding/4;
        }

        &__label {
            margin-right: $padding/2;
        }
    }
}
