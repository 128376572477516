@mixin elmer-chart-sizes {
    $sizes: (xxsmall: 50px, xsmall: 100px, small: 150px, medium: 200px, large: 300px, xlarge: 500px);

    @each $name, $size in $sizes {
        &--#{$name} {
            height: $size;
        }
    }
}

@mixin elmer-chart-utils {
    .chart-container {
        @include elmer-chart-sizes;
    }
}
