@mixin elmer-OnlineIndicator {
    .elmer-OnlineIndicator__label {
        margin: 0 4px;
    }

    .elmer-OnlineIndicator__online {
        color: $green-01;
    }

    .elmer-OnlineIndicator__offline {
        color: $medium-grey-02;
    }
}
