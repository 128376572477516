@mixin elmer-NavBar-Menu($shared, $prefix) {
    #{$prefix}-Menu {
        margin-left: map_get($shared, item-padding);

        &__submenu:not(:first-of-type) {
            &#{$prefix}-Menu__divider {
                border-top: 1px solid map_get($shared, border-color);
                margin-top: 8px;
            }
        }

        &__item {
            line-height: map_get($shared, link-height);
            padding: 0 map_get($shared, item-padding);
            color: map_get($shared, color);
            opacity: map_get($shared, opacity);
            font-size: map_get($shared, font-size);
            min-width: map_get($shared, min-item-width);

            &--link {
                @extend %anchor-fade;

                &:hover,
                &:focus {
                    color: map_get($shared, active-color);
                    opacity: map_get($shared, active-opacity);
                    font-weight: map_get($shared, active-font-weight);
                    background-color: map_get($shared, hover-background-color);
                }
            }

            &--title {
                font-weight: bold;
            }

            &--no-siblings {
                display: none;
            }
        }

        &--no-sibling-menus {
            margin: 0;
        }

        &__spinner {
            padding: 0 map_get($shared, item-padding);
        }
    }
}
