/**
 * This file allows us to deterministically order the generated rules.
 * This pattern should be applied all the way down into each imported module.
 */
@import "variables";
@import "elmer-foundation/elmer-foundation";
@import "utils";
@import "../charts/index";
@import "../components/index";
@import "../infrastructure/index";

// First, any external rules.
@include elmer-foundation;

// Then any global/public rules
@include elmer-style-utils;

// Components should be the first custom/internal rules, since other modules _may_ need to override or use specificity
@include elmer-components;

// Finally, everything else
@include elmer-charts;
@include elmer-infrastructure;
