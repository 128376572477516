@mixin elmer-KeyStatistic {
    .elmer-KeyStatistic {
        font-weight: 300;

        &--colored {
            color: $dark-grey-02;

            > small {
                color: $dark-grey-03;
            }
        }

        .font-weight-inherit {
            font-weight: inherit;
        }
    }

    .nowrap {
        white-space: nowrap;
    }
}
