@mixin elmer-infrastructure-ScopeSelector {
    $background-color: $white;
    $hover-color: $black;
    $active-color: $dark-grey-01;
    $inactive-color: $medium-grey-02;
    $font-weight: normal;
    $font-size: 1.333rem;

    .elmer-infrastructure-ScopeSelector {
        min-width: 400px;
        max-height: calc(100vh - (163px + 51px));
        overflow-y: auto;
        z-index: 2;
        font-size: $next-global-font-size;
        border-radius: 0 0 2px 2px;

        // TODO does this really need to be here?
        &,
        .card-divider,
        .card-section,
        input[type="text"] {
            background-color: $background-color;
        }
    }
}
