@mixin elmer-Table-Filter {
    .elmer-Table-Filter-Popup {
        padding: 0;

        button {
            cursor: pointer;
        }

        &__filter-icon {
            color: $medium-grey-03;

            &:hover {
                color: inherit;
            }
        }

        &__popper {
            color: inherit;
            z-index: 4;
            background-color: $white;
            padding: 10px;
            min-width: 160px;
            border: 1px solid $medium-grey-03;
            white-space: normal;
        }

        &__arrow {
            color: inherit;
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            border-width: 0 5px 5px;
            border-color: transparent transparent $medium-grey-03 transparent;
            top: -5px;
            left: calc(50% - 5px);
            margin: 0 5px;
        }
    }

    .elmer-Table-Filter-Tags {
        padding: 8px 8px 12px 8px;

        a {
            padding-right: 10px;
        }
    }
}
