@mixin elmer-Modal {
    $z-index: 1;

    .elmer-Modal {
        &--tiny {
            width: 30%;
        }

        &--small {
            width: 50%;
        }

        &--large {
            width: 90%;
        }
    }

    .elmer-ModalHeader {
        z-index: $z-index;
    }

    .elmer-ModalBody {
        min-height: 10vh;
        padding: 1rem;
        overflow-y: auto;
        max-height: 80vh;
        z-index: $z-index + 1;
    }

    .elmer-ModalFooter {
        z-index: $z-index;
    }
}
