// These are top level variables that can be used externally to style custom nav components
$elmer-navbar-settings: (
    color: rgba(255, 255, 255, 0.6),
    opacity: 1,
    active-color: $white,
    active-opacity: 1,
    active-font-weight: 600,
    background-color: #425563,
    secondary-background-color: #566676,
    hover-background-color: #425563,
    banner-background-color: $brand-color,
    border-radius: 2px,
    border-color: #566676,
    item-padding: 15px,
    link-height: 30px,
    font-size: 18px,
    min-item-width: 120px,
    height: 60px,
    dropdown-icon-spacing: 8px,
    dropdown-divider: 2px solid rgba(255, 255, 255, 0.4)
);

%fade {
    transition: all 0.25s ease-in-out;
}

%anchor-fade {
    transition: background-color 0.25s ease-out;
}

@mixin elmer-NavBar-SearchInput($settings) {
    $settings: map_merge((font-size: 18px, color: map_get($elmer-navbar-settings, color), padding: 20px, background-color: #566676), $settings);

    padding: map_get($settings, padding) / 2 map_get($settings, padding);
    background-color: map_get($settings, background-color);

    input[type="text"],
    input[type="text"]:focus {
        flex: 1;
        border: none;
        outline: none;
        box-shadow: none;
        font-size: map_get($settings, font-size);
        padding: 0;
        color: map_get($settings, color);
        background-color: map_get($settings, background-color);
        border-bottom: 1px solid transparent;
    }

    input[type="text"]:focus {
        border-bottom-color: rgba(255, 255, 255, 0.6);
    }

    label {
        padding-right: 1ch;
        font-size: map_get($settings, font-size);
        color: map_get($settings, color);
    }
}
