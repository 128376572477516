/* stylelint-disable no-descending-specificity */
// Foundation Settings
$pagination-arrows: false;
$pagination-font-size: rem-calc(14);
$pagination-margin-bottom: 0;
$pagination-item-color: $black;
$pagination-item-padding: rem-calc(3 10);
$pagination-item-spacing: rem-calc(1);
$pagination-radius: $global-radius;
$pagination-item-background-hover: $medium-grey-01;
$pagination-item-background-current: $primary-color;
$pagination-item-color-current: foreground($pagination-item-background-current);
$pagination-item-color-disabled: $medium-gray;
$pagination-item-padding: 0;
$pagination-item-spacing: 0;
$pagination-ellipsis-color: $black;
$pagination-mobile-items: false;

@import "~foundation-sites/scss/components/pagination";

@mixin elmer-Pagination {
    @include foundation-pagination;

    .pagination {
        display: inline-block;
    }

    .pagination-previous,
    .pagination-next {
        width: 1.25rem;
        height: 1rem;
        text-align: center;
        line-height: 1;

        > a {
            color: inherit;
            text-decoration: none;

            &:not(.disabled):hover {
                color: $white;
                border-color: $pagination-item-background-hover;
            }

            &.disabled {
                cursor: default;
            }
        }
    }

    .pagination-previous > a {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        border: 1px solid $medium-grey-03;
    }

    .pagination-next > a {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
        border: 1px solid $medium-grey-03;
        border-left: 0;
    }

    .elmer-Pagination {
        color: $dark-grey-02;
        margin: 0;

        &__count {
            color: $medium-grey-01;
        }

        &__range {
            margin-right: 10px;
        }
    }
}
