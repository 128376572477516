@mixin elmer-FavoritesIcon {
    div.elmer-block-pointer {
        cursor: pointer !important;
        text-align: center;

        i {
            margin-top: 5px;
        }
    }

    .elmer-mark-system-favorite {
        color: #fed676;
    }

    .elmer-mark-system-non-favorite {
        color: #888;
    }
}
