:global .shepherd-content {
  outline: none;
}

:global .shepherd-element {
  box-shadow: 0 1px 8px 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
  outline: none;
  transition: opacity 0.3s;
  z-index: 9998;
}

:global .shepherd-enabled.shepherd-element {
  opacity: 1;
}

:global .shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
}

:global .shepherd-element,
:global .shepherd-element *,
:global .shepherd-element :after,
:global .shepherd-element :before {
  box-sizing: border-box;
}

:global .shepherd-arrow {
  z-index: -1;
}

:global .shepherd-arrow-shadow {
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
}

:global .shepherd-arrow-shape {
  background-color: white;
  width: 24px;
  height: 24px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

:global .shepherd-arrow-shape:before {
  content: '';
}

:global .shepherd-element[data-popper-placement^='top'] > .shepherd-arrow {
  bottom: 12px;
  margin-left: -12px;
}

:global .shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow {
  top: -12px;
  margin-left: -12px;
}

:global .shepherd-element[data-popper-placement^='left'] > .shepherd-arrow {
  right: 12px;
  margin-top: -12px;
}

:global .shepherd-element[data-popper-placement^='right'] > .shepherd-arrow {
  left: -12px;
  margin-top: -12px;
}

:global .shepherd-element[data-popper-placement^='top'] > .shepherd-arrow-overlay {
  bottom: -12px;
  margin-left: -12px;
  left: 50%;
}

:global .shepherd-element[data-popper-placement^='bottom'] > .shepherd-arrow-overlay {
  top: -12px;
  margin-left: -12px;
  left: 50%;
}

:global .shepherd-element[data-popper-placement^='left'] > .shepherd-arrow-overlay {
  right: -12px;
  margin-top: -12px;
  top: 50%;
}

:global .shepherd-element[data-popper-placement^='right'] > .shepherd-arrow-overlay {
  left: -12px;
  margin-top: -12px;
  top: 50%;
}

:global .shepherd-arrow.hide ~ .shepherd-arrow-overlay {
  display: none;
}

:global .shepherd-element.shepherd-has-title[data-popper-placement^='bottom']
> .shepherd-arrow:before {
  background-color: #e6e6e6;
}

:global .shepherd-target-click-disabled.shell-shepherd-enabled.shell-shepherd-target,
:global .shepherd-target-click-disabled.shell-shepherd-enabled.shell-shepherd-target
* {
  pointer-events: none;
}

:global .shepherd-modal-overlay-container {
  -ms-filter: progid:dximagetransform.microsoft.gradient.alpha(Opacity=50);
  filter: alpha(opacity=50);
  fill-rule: evenodd;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}

:global .shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 10%;
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}

:global .shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

:global .shepherd-header,
:global .shepherd-footer {
  display: none;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #dddddd;
}

.dot.active {
  background: #444444;
}

.callout {
  width: 600px;
  max-height: 600px;
}

