@mixin elmer-NumberedIcon {
    .elmer-NumberedIcon {
        &__score {
            position: relative;
            background-color: white;
            margin-left: 13px;
            margin-top: -16px;
            border-radius: 10px;
            font-size: 11px;
            text-align: center;
            color: black;
            width: 17px;
        }
    }
}
