$topbar-padding: 0 5px;
$topbar-background: map_get($elmer-navbar-settings, background-color);
$topbar-banner-background: map_get($elmer-navbar-settings, banner-background-color);
$topbar-submenu-background: $topbar-background;
$topbar-title-spacing: 0.5rem 1rem 0.5rem 0;
$topbar-input-width: 100%;
$topbar-unstack-breakpoint: medium;

@import "~foundation-sites/scss/components/top-bar";

@mixin elmer-NavBar-TopBar($shared, $prefix) {
    @include foundation-top-bar;

    #{$prefix} {
        &.top-bar {
            margin: 0;
            z-index: 3;
            font-family: $header-font-family;
        }

        .top-bar-left,
        .top-bar-right {
            margin: 0;
            height: map_get($shared, height);
        }

        .top-bar-title {
            padding: 0 map_get($shared, item-padding);

            a {
                color: $light-grey-03;
            }
        }

        &__item--bordered::before {
            content: "|";
            color: map_get($shared, color);
            align-self: center;
            font-size: 2em;
            font-weight: 100;
            position: relative;
            top: -2px;
        }

        &__trigger {
            align-items: stretch;
            color: map_get($shared, color);
            font-size: map_get($shared, font-size);
            font-weight: normal;
            opacity: map_get($shared, opacity);
            flex: 1 1 auto;
            padding: 0 map_get($shared, item-padding);

            &:focus,
            &:hover,
            &--active {
                color: map_get($shared, active-color);
                text-decoration: none;
                opacity: map_get($shared, active-opacity);
                cursor: pointer;
                font-weight: map_get($shared, active-font-weight);
            }
        }

        &__banner {
            color: map_get($shared, active-color);
            background-color: $topbar-banner-background;
            padding: 3px 15px;
            margin: 4px 0;
            border-radius: 4px;

            a {
                color: map_get($shared, active-color);
                font-weight: map_get($shared, active-font-weight);

                &:active,
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }

        &__banner-icon {
            display: block;
            font-size: 30px;
            padding-right: 15px;
        }
    }
}
