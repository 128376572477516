@mixin elmer-NavBar-Dropdown($shared, $prefix) {
    #{$prefix}-Dropdown {
        border-bottom-left-radius: map_get($shared, border-radius);
        border-bottom-right-radius: map_get($shared, border-radius);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        z-index: 5;

        > ul {
            margin: 0;
            padding-bottom: map_get($shared, item-padding) / 2;
        }

        &__trigger-icon {
            margin-left: map_get($shared, dropdown-icon-spacing);
            margin-top: -2px;
        }

        &__divider-right {
            border-right: map_get($shared, dropdown-divider);
        }

        &__divider-left {
            border-left: map_get($shared, dropdown-divider);
        }
    }
}
