@mixin elmer-TimeRangeSelector {
    .elmer-TimeRangeSelector {
        display: flex;
        align-items: center;

        > .button {
            margin: 0 0.1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .elmer-TimeRangeSelector-CustomRangeModal {
        .elmer-ModalBody {
            min-height: 14rem;
        }

        &.custom-date-modal {
            top: 35%;
        }
    }

    .elmer-TimeRangeSelectorCard {
        & > div:first-child {
            font-size: 1rem;
            line-height: 28px;
        }
    }
}
