.trigger {
  align-items: stretch;
  font-size: 18px;
  font-weight: normal;
  opacity: 1;
  flex: 1 1 auto;
  padding: 0 15px;
  color: #ddd;
}

.trigger:focus,
.trigger:hover,
.trigger.active {
  color: #fff;
  text-decoration: none;
  opacity: 1;
  cursor: pointer;
  font-weight: 600;
}

