$elmer-globalbanner-colors: (
    info: $blue-01,
    warning: $warning-color,
    success: $success-color,
    error: $alert-color
);

@mixin type-context($type) {
    $type-selector: "--severity-#{$type}";

    &#{$type-selector} {
        $bg-color: map-get($elmer-globalbanner-colors, $type);

        background-color: $bg-color;
        color: color-pick-contrast($bg-color, ($white, $dark-grey-02));
    }
}

@mixin elmer-GlobalBanner {
    .elmer-GlobalBanner {
        font-size: $next-global-font-size;

        body[data-headless-browser="true"] & {
            display: none;
        }

        @include type-context('info');
        @include type-context('warning');
        @include type-context('success');
        @include type-context('error');

        padding: 10px;
        margin: 0;
        line-height: normal;

        a {
            text-decoration: underline;
            color: inherit;
        }

        &__content {
            overflow: hidden;
        }

        &__icons {
            float: left;
            width: 3em;
            text-align: center;
        }

        &__nav {
            float: right;
            max-width: 220px;
        }

        &__dismiss {
            margin: 0 1em;
            text-decoration: underline;
            padding: 0;
            color: inherit;
            background-color: transparent;
            border: none;
            cursor: pointer;

            &:focus {
                outline: 0;
            }
        }

        &__navigate {
            padding: 0 6px;
            cursor: pointer;
            color: inherit;
        }

        &__location {
            white-space: nowrap;
        }
    }
}

@include elmer-GlobalBanner;
