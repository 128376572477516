@mixin elmer-Headline {
    .elmer-Headline {
        color: $dark-grey-02;
        background-color: $white;

        // without this, margin would be set to auto
        // resulting in big margins that distort each section
        .elmer-Headline__Section div.row {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .elmer-Headline__Section {
        border-right: 1px solid $light-grey-01;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &:last-child {
            border-right: none;
        }

        .elmer-Headline {
            padding: 0;
            border: none;
        }

        .elmer-Headline__Section {
            border-right: none;
        }

        h6 {
            line-height: normal;
            margin-bottom: 0;
            height: 2.2rem;
        }

        .stacked-title {
            line-height: 0.9rem;
        }
    }
}
