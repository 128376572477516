@mixin elmer-infrastructure-ObjectPage {
    .elmer-infrastructure-ObjectPage {
        &__detail-header,
        &__collection-header {
            background-color: $white;
            border-bottom: 1px solid $medium-grey-03;
            width: 100%;
        }

        &__detail-header {
            padding: 12px 20px;
        }
    }
}
