@import "~@infosight/elmer/dist/sass/variables"; @import "src/variables";
.name {
  max-width: 300px;
  font-size: 1.1em;
  font-weight: normal;
}

.title {
  font-size: 0.9em;
  line-height: 0.9em;
}

.caret {
  margin-left: 2px;
}
