// Foundation Settings
$accordion-background: #eff1f5;
$accordion-item-color: $dark-grey-02;
$accordion-item-background-hover: $light-grey-01;
$accordion-item-padding: 10px 30px;
$accordion-content-border: none;
$accordion-content-background: $light-grey-05;
$accordion-content-padding: $accordion-item-padding;
$accordion-title-font-size: 1rem;

@import "~foundation-sites/scss/components/accordion";

@mixin elmer-Accordion {
    @include foundation-accordion;

    .accordion {
        font-size: 1rem;

        .accordion-item {
            position: relative;

            &:not(.is-active) {
                border-bottom: 1px solid rgba(208, 212, 218, 0.5);
            }
        }

        .accordion-title {
            position: relative;

            &.is-open {
                border-bottom: 1px solid rgba(208, 212, 218, 0.5);

                &::before {
                    font-family: "FontAwesome"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
                    font-size: 1.25rem;
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    margin-top: -0.9rem;
                    content: "\f107";
                }
            }

            &:hover,
            &:focus {
                background-color: $light-grey-05;
            }

            &::before {
                font-family: "FontAwesome"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
                font-size: 1.25rem;
                position: absolute;
                top: 50%;
                right: 1rem;
                margin-top: -0.9rem;
                content: "\f105";
            }
        }

        .accordion-content {
            display: block !important;
            border-bottom: 1px solid rgba(208, 212, 218, 0.5) !important;
        }
    }

    .Collapsible {
        position: relative;
    }

    .Collapsible__trigger {
        cursor: pointer;
    }

    .Collapsible__trigger-sibling {
        position: absolute;
        top: 0;
        right: calc(1rem + 1ch);

        // Copied from accordion-title in foundation
        line-height: 1;
        font-size: 1rem;
        color: $accordion-item-color;
        cursor: pointer;
        text-transform: uppercase;
    }
}
