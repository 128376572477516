@import "~foundation-sites/scss/components/button";

@mixin -lmr-Toggle($settings) {
    .elmer-Toggle {
        margin: map_get($settings, margin);

        &--increase-width-for-bold-weight {
            display: none;
        }
    }

    .elmer-Toggle__button {
        @include button-base;

        font-size: 1rem;
        border-radius: 0;
        margin: 0 2px 0 0;
        padding: 7px 15px;

        &--off {
            color: map_get($settings, off-color);
            font-weight: initial;

            &:hover,
            &:focus {
                color: map_get($settings, off-hover-color);
                outline: none;
            }
        }

        &--on,
        &--on:hover,
        &--on:focus {
            color: map_get($settings, on-color);
            background-color: map_get($settings, on-background-color);
            font-weight: bold;
        }
    }
}

@mixin -lmr-ToggleTabs {
    $elmer-toggle-button-background-color: #eff1f5;
    $elmer-toggle-button-color: $dark-grey-02;
    $elmer-toggle-button-on-background-color: $elmer-toggle-button-background-color;
    $elmer-toggle-button-on-color: $elmer-toggle-button-color;
    $height: 40px;

    .elmer-ToggleTabs {
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: $elmer-toggle-button-background-color;
        display: flex;
        justify-content: flex-start;
        height: $height;
        line-height: $height;
        font-size: 13 / 12rem;
        color: $elmer-toggle-button-color;

        &__button {
            display: inline-block;
            padding: 0 20px;
            height: 100%;
            line-height: inherit;
            cursor: pointer;
            color: $elmer-toggle-button-color;
            position: relative;

            &:focus,
            &:hover {
                text-decoration: none;
                color: $black;
            }

            &--on {
                color: $elmer-toggle-button-on-color;
                border-bottom: 2px solid $brand-color;
                background: $elmer-toggle-button-on-background-color;
                font-weight: bold;
            }
        }

        &--table-context {
            border-bottom: 1px solid $light-grey-03;
            justify-content: flex-end;
        }

        &--increase-width-for-bold-weight {
            display: block;
            font-weight: bold;
            height: 1px;
            color: transparent;
            overflow: hidden;
            visibility: hidden;
        }
    }
}

@mixin elmer-Toggle {
    @include -lmr-Toggle((off-background-color: tranparent, off-color: #757575, off-hover-color: $black, on-background-color: $dark-grey-01, on-color: $light-grey-05, margin: 8px 0,));
    @include -lmr-ToggleTabs;
}
