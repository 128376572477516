// Foundation Settings
$badge-background: map-get($foundation-palette, dark);
$badge-color: $white;
$badge-color-alt: $black;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: rem-calc(12);
$badge-palette: ('success': $success-color, 'alert': $alert-color, 'warning': $warning-color, 'dark': map-get($foundation-palette, dark));

@import "~foundation-sites/scss/components/badge";

@mixin elmer-Badge {
    @include foundation-badge;

    .badge {
        border-radius: 1em;
    }
}
