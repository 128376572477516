@mixin elmer-Tag {
    $font-size: 1rem;

    .elmer-Tags {
        font-size: $font-size;
        line-height: 2em;
    }

    .elmer-Tag {
        cursor: pointer;
        border-radius: 1em;
        background-color: $light-grey-01;
        color: $dark-grey-01;
        position: relative;
        padding: 4px 2em 4px 1em;
        display: inline-block;
        margin-right: $font-size / 3;

        &::after {
            font-family: "FontAwesome"; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
            position: absolute;
            font-size: 1rem;
            right: 0.6em;
            content: "\f00d";
        }
    }

    // TODO this class and usage are noncompliant
    .facet-error {
        background-color: $error-background;
        border: 1px solid $red-01;
    }
}
